<template>
  <svg id="blueprint" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g id="Сгруппировать_1311" data-name="Сгруппировать 1311" transform="translate(22.956)">
      <g id="Сгруппировать_1310" data-name="Сгруппировать 1310">
        <path id="Контур_1692" data-name="Контур 1692" d="M235.709,0a1.044,1.044,0,0,0-1.044,1.044V30.261a1.044,1.044,0,1,0,2.087,0V1.044A1.044,1.044,0,0,0,235.709,0Z" transform="translate(-234.666)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1313" data-name="Сгруппировать 1313" transform="translate(37.565 20.87)">
      <g id="Сгруппировать_1312" data-name="Сгруппировать 1312">
        <path id="Контур_1693" data-name="Контур 1693" d="M393.391,213.333h-8.348a1.044,1.044,0,1,0,0,2.087h8.348a1.044,1.044,0,1,0,0-2.087Z" transform="translate(-384 -213.333)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1315" data-name="Сгруппировать 1315" transform="translate(16.696 20.87)">
      <g id="Сгруппировать_1314" data-name="Сгруппировать 1314">
        <path id="Контур_1694" data-name="Контур 1694" d="M184.231,213.333H171.71a1.044,1.044,0,1,0,0,2.087h12.522a1.044,1.044,0,1,0,0-2.087Z" transform="translate(-170.666 -213.333)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1317" data-name="Сгруппировать 1317" transform="translate(0 20.87)">
      <g id="Сгруппировать_1316" data-name="Сгруппировать 1316">
        <path id="Контур_1695" data-name="Контур 1695" d="M9.391,213.333H1.044a1.044,1.044,0,1,0,0,2.087H9.391a1.044,1.044,0,1,0,0-2.087Z" transform="translate(0 -213.333)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1319" data-name="Сгруппировать 1319" transform="translate(22.956 37.565)">
      <g id="Сгруппировать_1318" data-name="Сгруппировать 1318">
        <path id="Контур_1696" data-name="Контур 1696" d="M235.709,384a1.044,1.044,0,0,0-1.044,1.044v8.348a1.044,1.044,0,1,0,2.087,0v-8.348A1.044,1.044,0,0,0,235.709,384Z" transform="translate(-234.666 -384)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1321" data-name="Сгруппировать 1321">
      <g id="Сгруппировать_1320" data-name="Сгруппировать 1320">
        <path id="Контур_1697" data-name="Контур 1697" d="M46.956,0H1.044A1.044,1.044,0,0,0,0,1.044V30.261A1.045,1.045,0,0,0,1.044,31.3H3.13a1.043,1.043,0,1,0,0-2.087H2.087V2.087H45.913V45.913H2.087V39.652H3.13a1.043,1.043,0,1,0,0-2.087H1.044A1.044,1.044,0,0,0,0,38.609v8.348A1.044,1.044,0,0,0,1.044,48H46.956A1.044,1.044,0,0,0,48,46.956V1.044A1.044,1.044,0,0,0,46.956,0Z" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1323" data-name="Сгруппировать 1323" transform="translate(20.87 29.217)">
      <g id="Сгруппировать_1322" data-name="Сгруппировать 1322">
        <path id="Контур_1698" data-name="Контур 1698" d="M218.55,298.667h-4.174a1.043,1.043,0,1,0,0,2.087h4.174a1.043,1.043,0,1,0,0-2.087Z" transform="translate(-213.333 -298.667)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1325" data-name="Сгруппировать 1325" transform="translate(20.87 37.565)">
      <g id="Сгруппировать_1324" data-name="Сгруппировать 1324">
        <path id="Контур_1699" data-name="Контур 1699" d="M218.55,384h-4.174a1.044,1.044,0,0,0,0,2.087h4.174a1.044,1.044,0,1,0,0-2.087Z" transform="translate(-213.333 -384)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1327" data-name="Сгруппировать 1327" transform="translate(8.348 18.783)">
      <g id="Сгруппировать_1326" data-name="Сгруппировать 1326">
        <path id="Контур_1700" data-name="Контур 1700" d="M86.377,192a1.044,1.044,0,0,0-1.044,1.044v4.174a1.044,1.044,0,0,0,2.087,0v-4.174A1.044,1.044,0,0,0,86.377,192Z" transform="translate(-85.333 -192)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1329" data-name="Сгруппировать 1329" transform="translate(16.696 18.783)">
      <g id="Сгруппировать_1328" data-name="Сгруппировать 1328">
        <path id="Контур_1701" data-name="Контур 1701" d="M171.71,192a1.044,1.044,0,0,0-1.044,1.044v4.174a1.044,1.044,0,1,0,2.087,0v-4.174A1.044,1.044,0,0,0,171.71,192Z" transform="translate(-170.666 -192)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1331" data-name="Сгруппировать 1331" transform="translate(29.217 18.783)">
      <g id="Сгруппировать_1330" data-name="Сгруппировать 1330">
        <path id="Контур_1702" data-name="Контур 1702" d="M299.71,192a1.044,1.044,0,0,0-1.044,1.044v4.174a1.044,1.044,0,1,0,2.087,0v-4.174A1.044,1.044,0,0,0,299.71,192Z" transform="translate(-298.666 -192)" fill="#7e8299"/>
      </g>
    </g>
    <g id="Сгруппировать_1333" data-name="Сгруппировать 1333" transform="translate(37.565 18.783)">
      <g id="Сгруппировать_1332" data-name="Сгруппировать 1332">
        <path id="Контур_1703" data-name="Контур 1703" d="M385.044,192A1.044,1.044,0,0,0,384,193.044v4.174a1.044,1.044,0,1,0,2.087,0v-4.174A1.044,1.044,0,0,0,385.044,192Z" transform="translate(-384 -192)" fill="#7e8299"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BluePrint"
}
</script>

<style scoped>

</style>
