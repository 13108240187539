<template>
  <!--begin::Floor-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ floor.name }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ floor.office.name }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button v-b-modal.modal-add-edit-floor
                  class="btn bg-light-primary font-weight-bolder font-size-sm">
          {{ $t("MANAGEMENT.EDIT_BUTTON") }}
        </b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Dropzone-->
      <template v-if="(isEdit || toggleEdit) && !isFileAdded">
        <vue-dropzone ref="dropzone" id="drop-zone"
                      :options="dropOptions"
                      :useCustomSlot=true
                      @vdropzone-error="uploadError"
                      @vdropzone-complete="afterComplete">
          <div class="dropzone-custom-content">
            <BluePrint />
            <h5 class="card-label font-weight-bolder text-dark mt-6">
              {{ $t("MANAGEMENT.NO_FLOOR_PLAN_YET_TITLE") }}
              <span class="d-block font-weight-lighter py-3 font-size-sm">{{ $t("MANAGEMENT.NO_FLOOR_PLAN_YET_TITLE_DESC") }}</span>
            </h5>
            <button class="btn btn-outline-secondary font-weight-bolder font-size-sm">{{ $t("MANAGEMENT.NO_FLOOR_PLAN_YET_BUTTON") }}</button>
          </div>
        </vue-dropzone>
        <div class="w-100 my-4">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder font-size-sm"
              @click="$router.go(-1)">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder font-size-sm mr-2"
                variant="secondary"
                @click="removeAllFiles">
              {{ $t("MANAGEMENT.RESET_BUTTON") }}
            </b-button>
            <b-button
                class="font-weight-bolder font-size-sm"
                variant="primary"
                :disabled="notReadySave"
                @click="saveFile">
              {{ $t("MANAGEMENT.SAVE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
      <!--end::Dropzone-->
      <!--begin::ViewZone-->
      <template v-if="(!isEdit && !toggleEdit) || isFileAdded">
        <img :src="'data:image/svg+xml;base64, ' + floorImg.image" height="100%" width="100%" alt="">
        <div class="w-100 my-4">
          <p class="float-left">
            <b-button
                class="font-weight-bolder border-dark font-size-sm mr-2"
                variant="light"
                @click="$router.push({ name: 'floor-resources', params: { id: $route.params.id }, hash: '#desk' })">
              {{ $t("MANAGEMENT.SET_UP_DESKS_BUTTON") }}
            </b-button>
            <b-button
                class="font-weight-bolder border-dark font-size-sm"
                variant="light"
                @click="$router.push({ name: 'floor-resources', params: { id: $route.params.id }, hash: '#meeting-room' })">
              {{ $t("MANAGEMENT.SET_UP_MEETINGS_ROOMS_BUTTON") }}
            </b-button>
          </p>
          <b-button
              v-if="isCanEdit"
              class="float-right font-weight-bolder font-size-sm"
              variant="light-primary"
              @click="toggleEdit = true; isFileAdded = false">
            {{ $t("MANAGEMENT.CHANGE_FLOOR_PLAN_BUTTON") }}
          </b-button>
        </div>
      </template>
      <!--end::ViewZone-->
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Floor Modal-->
    <AddEditFloorModal
        :onPage="'floor'"
        :form="floor"
        :isEdit="true"
        @changeIsEdit="toggleIsEdit"/>
    <!--end::Add Edit Floor Modal-->
  </div>
  <!--begin::Floor-->
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_FLOOR, GET_FLOOR_IMG, POST_FLOOR_IMG } from "@/core/services/store/management.module";

import BluePrint from "@/view/layout/svg/BluePrint";
import AddEditFloorModal from "@/view/layout/modal/AddEditFloorModal";

export default {
  name: "Floor",
  components: {
    vueDropzone,
    BluePrint,
    AddEditFloorModal
  },
  mounted() {
    this.$store.dispatch(GET_FLOOR, this.$route.params.id).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MANAGEMENT.OFFICES_TITLE"), route: "/management/offices" },
        { title: `${this.floor.office.name}`, route: `/management/offices/office/${this.floor.office.id}` },
        { title: `${this.floor.name}`}
      ]);
    });
    this.$store.dispatch(GET_FLOOR_IMG, this.$route.params.id);
  },
  data() {
    return {
      isFileAdded: false,
      isEditFloor: false,
      toggleEdit: false,
      notReadySave: true,
      dropOptions: {
        url: "https://httpbin.org/post",
        maxFilesize: 1, // MB
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
          });
        },
        acceptedFiles: '.svg',
        thumbnailWidth: 350,
        // addRemoveLinks: true
      },
      fileToUpload: ''
    }
  },
  computed: {
    ...mapGetters([
      "floor",
      "floorImg"
    ]),
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => {
          if (item === this.$route.meta.permissions[1]) return item;
        });
        return role !== undefined;
      }
    },
    isEdit() {
      return this.floor.plan.image === null;
    }
  },
  methods: {
    uploadError() {
      const $ = require('jquery');
      $('.dz-error-message span').text(this.$t('MANAGEMENT.FLOOR_PLAN_WARNING_TEXT'));
    },
    toggleIsEdit(variable) {
      this.isEditFloor = variable;
    },
    afterComplete(file) {
      if (file.status === 'success') {
        this.notReadySave = false;
        this.fileToUpload = file;
      }
    },
    saveFile () {
      let formData  = new FormData();
      formData.append("file", this.fileToUpload);
      this.$store.dispatch(POST_FLOOR_IMG, { id: this.$route.params.id, img: formData })
          .then(() => {
            this.isFileAdded = true;
            this.toggleEdit = false;
            this.notReadySave = true;
            this.$store.dispatch(GET_FLOOR_IMG, this.$route.params.id);
          });
    },
    removeAllFiles() {
      this.notReadySave = true;
      this.$refs.dropzone.removeAllFiles();
    }
  }
}
</script>

<style lang="scss">
  #drop-zone {
    position: relative;
    min-height: 20rem;
    padding: 0;
    border: 2px dashed #B6B4C3;
    border-radius: 0.42rem;
    background: #F2F6F9;
  }

  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  #drop-zone .dz-preview {
    margin: 0;
    .dz-image img {
      width: inherit!important;
    }
  }
</style>
