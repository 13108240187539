<template>
  <b-modal
      id="modal-add-edit-floor"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_FLOOR_TITLE") : $t("MANAGEMENT.ADD_FLOOR_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.FLOOR_INVALID_FEEDBACK')"
          :state="nameState"
      >
        <template slot="label">{{ $t("MANAGEMENT.FLOOR_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.FLOOR_PLACEHOLDER')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              v-if="!isEdit"
              class="font-weight-bolder mr-2"
              variant="secondary"
              @click="handleOkAndGo">
            {{ $t("MANAGEMENT.ADD_SETTING_BUTTON") }}
          </b-button>
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("MANAGEMENT.SAVE_BUTTON") : $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { GET_OFFICE, GET_FLOOR, POST_FLOOR, PUT_FLOOR } from "@/core/services/store/management.module";

export default {
  name: "AddEditFloorModal",
  mounted() {
  },
  props: {
    onPage: {
      type: String
    },
    form: {
      type: Object,
      default: ()=> {
        return {
          name: ''
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nameState: null
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      if (this.onPage === 'office') {
        let emptyForm = {
          name: ''
        };
        this.$emit('changeForm', emptyForm);
      }
      this.$emit('changeIsEdit', false);
      this.nameState = null;
      if (this.onPage === 'office') this.$store.dispatch(GET_OFFICE, this.$route.params.id)
      else if (this.onPage === 'floor') this.$store.dispatch(GET_FLOOR, this.$route.params.id);
      this.$bvModal.hide('modal-add-edit-floor');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleOkAndGo(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit('nextStep');
    },
    handleSubmit(next) {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.isEdit) {
        let form = {
          name: this.form.name,
          floorId: this.form.id
        }
        if (this.onPage === 'office') {
          this.$store.dispatch(PUT_FLOOR, { form, id: this.$route.params.id })
            .then(() => this.$store.dispatch(GET_OFFICE, this.$route.params.id));
        } else if (this.onPage === 'floor') {
          this.$store.dispatch(PUT_FLOOR, { form, id: this.form.office.id })
              .then(() => this.$store.dispatch(GET_OFFICE, this.form.office.id));
        }
      } else {
        let newFloorName = this.form.name;
        this.$store.dispatch(POST_FLOOR, { form: this.form, id: this.$route.params.id })
          .then(res => {
            this.$store.dispatch(GET_OFFICE, this.$route.params.id)
            if (next) {
              let newFloor = res.floors.find(floor => floor.name === newFloorName);
              this.$router.push({ name: 'floor', params: { id: `${newFloor.id}` } })
            }
          });
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-edit-floor');
      })
    },
  }
}
</script>

<style scoped>

</style>
